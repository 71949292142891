/**
 * ATTENTION: This file is auto generated by using "prepareTemplates".
 * Do not change the content!
 *
 */

/**
 * Library Index span to autogenerate all the elements and extensions
 * Used by "prepareSpans"
 */

import Span from './span/Span'
export * from './span/Span'
export default Span
