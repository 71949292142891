export const version = '10.49.0'

export function init() {
  if (typeof window !== 'undefined') {
    class Eufemia {
      get version() {
        return '10.49.0'
      }
    }

    window.Eufemia = new Eufemia()
  }
}
